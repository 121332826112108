import * as i0 from '@angular/core';
import { InjectionToken, inject, NgZone, ElementRef, EventEmitter, Directive, Input, Output } from '@angular/core';
import EmblaCarousel from 'embla-carousel';
import { canUseDOM, areOptionsEqual, arePluginsEqual } from 'embla-carousel-reactive-utils';
import { map, scan, filter, Subject, takeUntil } from 'rxjs';
const EMBLA_OPTIONS_TOKEN = new InjectionToken('embla global options', {
  factory: () => undefined
});
function provideEmblaGlobalOptions(options) {
  return [{
    provide: EMBLA_OPTIONS_TOKEN,
    useValue: options
  }];
}
// Solution from https://stackoverflow.com/a/53627167
function throttleDistinct(duration, equals = (a, b) => a === b) {
  return source => {
    return source.pipe(map(x => {
      return {
        value: x,
        time: Date.now(),
        keep: true
      };
    }), scan((acc, curr) => {
      const diff = curr.time - acc.time;
      const isSame = equals(curr.value, acc.value);
      return diff > duration || diff < duration && !isSame ? {
        ...curr,
        keep: true
      } : {
        ...acc,
        keep: false
      };
    }), filter(x => x.keep), map(x => x.value));
  };
}
class EmblaCarouselDirective {
  constructor() {
    this.globalOptions = inject(EMBLA_OPTIONS_TOKEN);
    this.ngZone = inject(NgZone);
    this._elementRef = inject(ElementRef);
    this.options = {};
    this.plugins = [];
    this.subscribeToEvents = [];
    this.eventsThrottleTime = 100;
    this.emblaChange = new EventEmitter();
    this.destroy$ = new Subject();
    this.storedOptions = this.options;
    this.storedPlugins = this.plugins;
    if (this.globalOptions) {
      EmblaCarousel.globalOptions = this.globalOptions;
    }
  }
  ngAfterViewInit() {
    if (!canUseDOM()) return;
    this.ngZone.runOutsideAngular(() => {
      this.emblaApi = EmblaCarousel(this._elementRef.nativeElement, this.storedOptions, this.storedPlugins);
    });
    this.listenEvents();
  }
  ngOnChanges(changes) {
    const {
      plugins,
      options
    } = changes;
    if (options && !areOptionsEqual(this.storedOptions, options.currentValue)) {
      this.storedOptions = options.currentValue;
      this.reInit();
    }
    if (plugins && !arePluginsEqual(this.storedPlugins, plugins.currentValue)) {
      this.storedPlugins = plugins.currentValue;
      this.reInit();
    }
  }
  scrollTo(...args) {
    this.ngZone.runOutsideAngular(() => this.emblaApi?.scrollTo(...args));
  }
  scrollPrev(...args) {
    this.ngZone.runOutsideAngular(() => this.emblaApi?.scrollPrev(...args));
  }
  scrollNext(...args) {
    this.ngZone.runOutsideAngular(() => this.emblaApi?.scrollNext(...args));
  }
  reInit() {
    if (!this.emblaApi) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.emblaApi?.reInit(this.storedOptions, this.storedPlugins);
    });
  }
  /**
   * `eventsThrottler$` Subject was made just because `scroll` event fires too often.
   */
  listenEvents() {
    if (this.subscribeToEvents.length === 0) {
      return;
    }
    const eventsThrottler$ = new Subject();
    eventsThrottler$.pipe(throttleDistinct(this.eventsThrottleTime), takeUntil(this.destroy$)).subscribe(eventName => {
      this.ngZone.run(() => this.emblaChange.emit(eventName));
    });
    this.ngZone.runOutsideAngular(() => {
      this.subscribeToEvents.forEach(eventName => {
        this.emblaApi.on(eventName, () => eventsThrottler$.next(eventName));
      });
    });
  }
  ngOnDestroy() {
    this.emblaApi?.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
EmblaCarouselDirective.ɵfac = function EmblaCarouselDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || EmblaCarouselDirective)();
};
EmblaCarouselDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EmblaCarouselDirective,
  selectors: [["", "emblaCarousel", ""]],
  inputs: {
    options: "options",
    plugins: "plugins",
    subscribeToEvents: "subscribeToEvents",
    eventsThrottleTime: "eventsThrottleTime"
  },
  outputs: {
    emblaChange: "emblaChange"
  },
  exportAs: ["emblaCarousel"],
  standalone: true,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmblaCarouselDirective, [{
    type: Directive,
    args: [{
      selector: '[emblaCarousel]',
      exportAs: 'emblaCarousel',
      standalone: true
    }]
  }], function () {
    return [];
  }, {
    options: [{
      type: Input
    }],
    plugins: [{
      type: Input
    }],
    subscribeToEvents: [{
      type: Input
    }],
    eventsThrottleTime: [{
      type: Input
    }],
    emblaChange: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of embla-carousel-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { EMBLA_OPTIONS_TOKEN, EmblaCarouselDirective, provideEmblaGlobalOptions };
